import { useStaticQuery, graphql } from "gatsby"
const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            telefono
            # truperADAPI
            API_TRUPERAD
            API_ACCIONFERRETERA
            getinfoTruperExtra
            GETINFO_TRUPEREXTRA
            siteId
            BUSQUEDAS_TRUPER
            BUSQUEDAS_PROMOTRUPER
            BUSQUEDAS_TRUPERCOMPRAS
            ASSIST_TRUPER_EXTRA_API
            FECHA_PROMOTRUPER
            FECHA_SUSCRIPCION_GRATIS
          }
        }
      }
    `
  )
  return site.siteMetadata
  // return {...site.siteMetadata, API_ACCIONFERRETERA:"/api/"}
  // return {...site.siteMetadata, API_ACCIONFERRETERA:"http://localhost:5000/api/firebase/test_"}
}

export default useSiteMetadata
