import React, { createContext, useState, useEffect, useRef } from "react"
import firebase from "acciondigital-basic-theme/src/utils/get-firebase-instance"
import { faeSetUserId, faeSetUserProperties, listasZ, zSubscribe } from "acciondigital-basic-theme/src/utils/gatgUtils"
import { usuariosControl_area, usuarios_area } from "acciondigital-basic-theme/src/utils/usuarios"

export const AuthContext = createContext()

const defaultInfoLoadingControl = {
  infoUsuarios: false,
  infoUsuariosControl: false,
  infoControl: false,
}

const AuthProvider = ({ sitio, getUserProperties, children }) => {
  const [user, setUser] = useState()
  const [infoUsuarios, setInfoUsuarios] = useState(undefined)
  const [infoUsuariosControl, setInfoUsuariosControl] = useState(undefined)
  const [infoControl, setInfoControl] = useState(undefined)
  const [controlCambio, setControlCambio] = useState()


  const infoLoadingControlRef = useRef({...defaultInfoLoadingControl})

  // console.log("AuthProvider sitio", { sitio })

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(usuario => {
      console.debug("AuthProvider onAuthStateChanged", {
        user: usuario,
        email: usuario?.email,
      })

      if (usuario?.uid) {
        setUser(usuario)
      } else {
        infoLoadingControlRef.current={...defaultInfoLoadingControl}
        setUser(undefined)
        setInfoUsuarios(undefined)
        setInfoUsuariosControl(undefined)
        setInfoControl(undefined)
        faeSetUserId(null)
        zSubscribe(null, listasZ.USUARIOS)
        zSubscribe(null, listasZ.CLIENTES)
        faeSetUserProperties({ esAdmin: null, tieneSuscripcion: null })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  //infoUsuarios
  useEffect(() => {
    const user_uid = user?.uid
    const refBase = `/app/${sitio}/${usuarios_area}/${user_uid}`
    const ref = firebase.database().ref(refBase)
    console.debug("AuthProvider useEffect usuarios", refBase)

    if (user_uid && sitio) {

      const onError = error => {
        // console.debug("🚀 ~ file: AuthContext.js:104 ~ useEffect ~ error", error.message)
        infoLoadingControlRef.current.infoUsuarios = true
        setControlCambio(new Date().getTime())
      }
      const listener = snapshot => {
        const datos = snapshot.val()
        // console.log({datos})
        infoLoadingControlRef.current.infoUsuarios=true
        if (datos) setInfoUsuarios(datos)
        else {
          setInfoUsuarios(undefined)
          setControlCambio(new Date().getTime())
        }
      }
      ref.on("value", listener, onError)
      return () => ref.off("value", listener)
    } else {
      setInfoUsuarios(undefined)
    }
  }, [user, sitio])

  //infoUsuariosControl
  useEffect(() => {
    const user_uid = user?.uid
    const refBase = `/app/${sitio}/${usuariosControl_area}/${user_uid}`
    const ref = firebase.database().ref(refBase)
    console.debug("AuthProvider useEffect usuarios-control", refBase)

    if (user_uid && sitio) {

      const onError = error => {
        // console.debug("🚀 ~ file: AuthContext.js:104 ~ useEffect ~ error", error.message)
        infoLoadingControlRef.current.infoUsuariosControl = true
        setControlCambio(new Date().getTime())
      }
      const listener = snapshot => {
        const datos = snapshot.val()
        // console.log({datos})
        infoLoadingControlRef.current.infoUsuariosControl=true
        if (datos) setInfoUsuariosControl(datos)
        else {
          setInfoUsuariosControl(undefined)
          setControlCambio(new Date().getTime())
        }
      }
      ref.on("value", listener, onError)
      return () => ref.off("value", listener)
    } else {
      setInfoUsuariosControl(undefined)
    }
  }, [user, sitio])

  //infoControl
  useEffect(() => {
    const user_uid = user?.uid
    const refBase = `/app/${sitio}/control/${user_uid}`
    const ref = firebase.database().ref(refBase)
    console.debug("AuthProvider useEffect control", refBase)

    if (user_uid && sitio) {

      const onError = error => {
        // console.debug("🚀 ~ file: AuthContext.js:104 ~ useEffect ~ error", error.message)
        infoLoadingControlRef.current.infoControl = true
        setControlCambio(new Date().getTime())
      }
      const listener = snapshot => {
        const datos = snapshot.val()
        // console.log({ control: datos })
        infoLoadingControlRef.current.infoControl = true
        if (datos) setInfoControl(datos)
        else {
          setInfoControl(undefined)
          setControlCambio(new Date().getTime())
        }
      }
      ref.on("value", listener, onError)
      return () => ref.off("value", listener)
    } else {
      setInfoControl(undefined)
    }
  }, [user, sitio])

  

  useEffect(() => {
    // const user_uid = user?.uid

    const userProperties = getUserProperties({
      user,
      infoControl,
      infoUsuarios,
      infoUsuariosControl,
    })
    if (
      user?.uid &&
      infoLoadingControlRef.current.infoControl &&
      infoLoadingControlRef.current.infoUsuarios &&
      infoLoadingControlRef.current.infoUsuariosControl
    ) {
      infoLoadingControlRef.current = { ...defaultInfoLoadingControl }
      faeSetUserId(user?.uid)
      zSubscribe(user.email, listasZ.USUARIOS)
      if(userProperties.tieneSuscripcion) 
        zSubscribe(user.email, listasZ.CLIENTES)
      faeSetUserProperties(userProperties, user?.uid && userProperties)
      
      console.debug("🚀 ~ file: AuthContext.js:140 ~ useEffect ~ userProperties", {
        user_uid: user?.uid,
        userProperties,
        infoLoadingControlRef: infoLoadingControlRef.current,
      })
    }
  }, [user, infoControl, infoUsuarios, infoUsuariosControl, controlCambio, getUserProperties])

  return (
    <AuthContext.Provider
      value={{
        user,
        infoUsuarios,
        infoUsuariosControl,
        infoControl,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
