import React from "react"
import "firebase/compat/auth"
import 'firebase/compat/database'
import 'firebase/compat/analytics'
import "bootswatch/dist/united/bootstrap.min.css"
import './src/css/global.css'

import { shouldUpdateScrollFn } from "acciondigital-basic-theme/src/utils/gatsbyBrowserUtils"
import { wrapPageElementQueryProviderBase as wrapPageElementQueryProvider } from "acciondigital-basic-theme/src/utils/wrapPageElementQueryProviderBase"

import RootElement from "./src/components/rootElement"
//alexluong/gatsby-packages
//gatsby-plugin-use-query-params jumps to top of the page when a query param is set #33
//https://github.com/alexluong/gatsby-packages/issues/33
export const shouldUpdateScroll = shouldUpdateScrollFn

  
export const wrapPageElement = wrapPageElementQueryProvider



  export const wrapRootElement = ({ element }) => (
    <RootElement>{element}</RootElement>
  )